import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Toaster } from 'react-hot-toast';
import { hydrate, render } from "react-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import ReactGA from 'react-ga4';


ReactGA.initialize('G-MBQYJM0ZDB');

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<>
    <App />
    <Toaster />
  </>, rootElement);
} else {
  render(<>
    <App />
    <Toaster />
  </>, rootElement);
}
// reportWebVitals();
