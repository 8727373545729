import React, { useState } from "react";

function VATCalculator() {
  const [amount, setAmount] = useState("");
  const [vatRate, setVATRate] = useState(15); // Default VAT rate in Bangladesh is 15%
  const [vatAmount, setVATAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalAmountExclude, setTotalAmountExclude] = useState(0);

  const calculateTotal = () => {
    const calculatedVAT = (parseFloat(amount) * vatRate) / 100;
    const total = parseFloat(amount) + calculatedVAT;

    setVATAmount(calculatedVAT.toFixed(2));
    setTotalAmount(total.toFixed(2));

    const exclusiveVatAmount =
      +amount - (+calculatedVAT / (+vatRate + 100)) * 100;

    setTotalAmountExclude(exclusiveVatAmount.toFixed(2));
  };

  const handleReset = () => {
    setAmount("");
    setVATRate(15); // Reset VAT rate to default
    setVATAmount(0);
    setTotalAmount(0);
    setTotalAmountExclude(0);
  };

  return (
    <div className="calculator-container">
      <h1 style={{ color: "white" }}>VAT Calculator</h1>
      <div>
        <label
          style={{ color: "white", fontSize: "17px", fontWeight: "700" }}
          htmlFor="amount"
        >
          Total Amount:{" "}
        </label>
        <input
          type="number"
          value={amount}
          id="amount"
          placeholder="Enter Your Amount"
          onChange={(e) => setAmount(e.target.value)}
        />
      </div>
      <div>
        <label
          style={{ color: "white", fontSize: "17px", fontWeight: "700" }}
          htmlFor="vatRate"
        >
          VAT Rate (%):{" "}
        </label>
        <input
          id="vatRate"
          type="number"
          value={vatRate}
          onChange={(e) => setVATRate(e.target.value)}
        />
      </div>
      <button
        className="form-submit-button"
        onClick={calculateTotal}
        style={{
          marginRight: "10px",
          fontSize: "17px",
          fontWeight: "700",
          border: "1px solid",
          borderRadius: "10px",
          padding: "8px 15px",
        }}
      >
        Calculate Total
      </button>
      <button
        className="form-submit-button"
        onClick={handleReset}
        style={{
          marginTop: "10px",
          marginRight: "10px",
          fontSize: "17px",
          fontWeight: "700",
          border: "1px solid",
          borderRadius: "10px",
          padding: "8px 15px",
        }}
      >
        Reset
      </button>
      {vatAmount > 0 && totalAmount > 0 && totalAmountExclude > 0 && (
        <div className="result">
          <table
            style={{
              fontSize: "15px",
              borderCollapse: "collapse",
              border: "1px solid #ddd",
              margin: "auto",
            }}
          >
            <tbody>
              <tr>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <b>VAT Amount</b>
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  {vatAmount} BDT
                </td>
              </tr>
              <tr>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <b>Including VAT Amount</b>
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  {totalAmount} BDT
                </td>
              </tr>
              <tr>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <b>Excluding VAT Amount</b>
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  {totalAmountExclude} BDT
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default VATCalculator;
